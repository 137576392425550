define('unity-mma/initializers/ember-google-map', ['exports', 'ember-google-map/utils/load-google-map'], function (exports, _loadGoogleMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(container, application) {
    application.register('util:load-google-map', _loadGoogleMap.default, { instantiate: false });
    application.inject('route', 'loadGoogleMap', 'util:load-google-map');
  }

  exports.default = {
    name: 'ember-google-map',
    initialize: initialize
  };
});