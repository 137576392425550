define('unity-mma/router', ['exports', 'unity-mma/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('site', function () {
      this.route('schedule');
      this.route('classes', function () {
        this.route('beginner');
        this.route('intermediate');
        this.route('advanced');
        this.route('kids');
        this.route('mma');
        this.route('kickboxing');
      });
      this.route('contact');
      this.route('train');
      this.route('pricing');
      this.route('spotlight');
      this.route('try-for-free');
      this.route('trial-thank-you');
    });
  });

  exports.default = Router;
});