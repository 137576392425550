define('unity-mma/routes/site/try-for-free', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			return this.store.createRecord('site/try-for-free');
		},


		actions: {
			signup: function signup() {
				var _this = this;

				var trial = this.modelFor(this.routeName);
				trial.save().then(function () {
					_this.transitionTo('site.trial-thank-you');
				}).catch(function (error) {
					console.log(error);
				});
			}
		}

	});
});