define('unity-mma/helpers', ['exports', 'google-maps-addon/helpers'], function (exports, _helpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'Helpers', {
    enumerable: true,
    get: function () {
      return _helpers.Helpers;
    }
  });
});