define("unity-mma/controllers/site/contact", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		mapOptions: {
			"latitude": "-100",
			"longitude": "250",
			"zoom": 5,
			"infowindow": {
				"content": "<div>InfoWindow</div>",
				"latitude": "11.976299881670053",
				"longitude": "250",
				"maxWidth": 500
			},
			"markers": [{
				"latitude": "-100",
				"longitude": "250",
				"title": "first marker",
				"animation": "DROP",
				"timeout": 2000,
				"draggable": true,
				"infowindow": {
					"content": "<div>Marker 1</div>"
				}
			}, {
				"latitude": "-100",
				"longitude": "250",
				"title": "first marker",
				"animation": "BOUNCE",
				"timeout": 4000,
				"draggable": false
			}]
		}

	});
});