define('unity-mma/helpers/load-google-maps', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.loadGoogleMaps = loadGoogleMaps;


	var API_KEY = 'AIzaSyDK77NzYyptW6jiVYTONQjyKXrDN7aRK1I';
	var LIBRARIES = [];

	var promise = void 0;

	/**
  * Loads the google map SDK
  *
  * @return {Ember.RSVP.Promise}
  */
	function loadGoogleMaps(resolveWith) {
		if (window.google && window.google.maps) {
			return Ember.RSVP.resolve(resolveWith);
		}

		if (promise) {
			return promise;
		}

		var src = 'https://maps.googleapis.com/maps/api/js?key=' + API_KEY + '&libraries=' + LIBRARIES.join(",");

		return promise = new Ember.RSVP.Promise(function (resolve, reject) {
			window.__emberGoogleMapLoaded__ = Ember.run.bind(function () {
				promise = null;
				window.__emberGoogleMapLoaded__ = null;
				resolve(resolveWith);
			});

			Ember.$.getScript(src + '&callback=__emberGoogleMapLoaded__').fail(function (jqXhr) {
				promise = null;
				window.__emberGoogleMapLoaded__ = null;
				reject(jqXhr);
			});
		});
	}

	exports.default = Ember.Helper.helper(loadGoogleMaps);
});